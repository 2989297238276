@import '~styles/common.scss';

.summer-cancel-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    .summer-cancel-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 503px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Open Sans', sans-serif;
        text-align: center;
        border-radius: 16px;
        border: 3px solid #3D5AF1;
        background: #f3f6fe;
        overflow: hidden;

        box-shadow:
            0px 10px 40px 0px rgba(143, 93, 255, 0.1),
            0px -4px 10px 0px rgba(0, 0, 0, 0.1) inset,
            0px 4px 10px 0px rgba(255, 255, 255, 0.05) inset;
        padding: 34px 26px;
        padding-bottom: 0px;
        z-index: 9999;

        @media #{$media-mobile} {
            width: 90%;
        }

        h1 {
            text-align: left; 
            color: #151515;
            font-family: 'Open Sans', sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px; /* 112.5% */
            margin-bottom: 26px;
        }

        p {
            color: #151515;
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 24px;

            strong {
                font-weight: 700;
            }
        }
    }

    .cta-btn {
        cursor: pointer;
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: #3d5af1;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        border: none;
        margin-bottom: 7px;

        @media #{$media-mobile} {
            font-size: 14px;
            height: 40px;
        }
    }

    .no-thanks-btn {
        cursor: pointer;
        color: #8d8d8d;
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-decoration-line: underline;
        margin-bottom: 11px;
    }

    .summber-break {
        height: 196px;
        object-fit: contain;
    }

    .background-modal {
        background: rgba($color: #000000, $alpha: 0.5);
        z-index: 9998;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
