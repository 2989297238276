@import '~styles/common.scss';

.subscription-details {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;

    @media #{$media-mobile} {
        flex-direction: column;
    }

    .plan-item {
        width: 300px;
        height: 40px;
        display: flex;
        align-items: center;

        @media #{$media-mobile} {
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
        }

        &.card-info {
            border-right: 1px solid rgba(255, 255, 255, 0.08);
            display: flex;

            @media #{$media-mobile} {
                margin-bottom: 20px;
                border-right: none;
            }
            img {
                height: 40px;
                object-fit: contain;
                margin-right: 16px;

                @media #{$media-mobile} {
                    margin-right: 8px;
                }
            }
            .card-details {
                h3 {
                    color: #fff;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    text-transform: capitalize;
                }
                p {
                    color: rgba(255, 255, 255, 0.64);
                    font-family: 'Open Sans', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
        }

        &.billing-email {
            @media #{$media-mobile} {
                align-items: center;
                margin-bottom: 16px;
            }
            .email-icon {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 1px solid rgba(255, 255, 255, 0.08);
                background: rgba(255, 255, 255, 0.02);
                margin-right: 8px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
            p {
                color: rgba(255, 255, 255, 0.64);
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
            }
        }
    }

    .edit-btn {
        cursor: pointer;
        display: flex;
        padding: 8px 16px;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        background: rgba(255, 255, 255, 0.04);

        color: #fff;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;

        margin-bottom: 0px;

        @media #{$media-mobile} {
            width: 100%;
            background: transparent;
            border: none;
            border-top: 1px solid rgba(255, 255, 255, 0.12);
            font-size: 14px;
            justify-content: center;
        }

        img {
            margin-left: 4px;
            width: 16px;
            height: 16px;

            @media #{$media-mobile} {
                width: 20px;
                height: 20px;
            }
        }
    }
}
