@import '~styles/common.scss';

.edit-payment-plan {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        margin-bottom: 8px;
        padding: 8px 24px;
        height: 40px;
        color: #fff;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;

        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: #8f5dff;
        outline: none;
        box-shadow: 0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset,
            0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset,
            0px 0px 16px 4px rgba(255, 255, 255, 0.12) inset,
            0px 0px 16px 4px rgba(143, 93, 255, 0.4), 0px 1px 2px 0px #6b26c2;

        @media #{$media-mobile} {
            width: 100%;
        }
    }

    p {
        text-align: center;
        color: #fff;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
    }
}
