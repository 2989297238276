@import '~styles/common.scss';

.semester-plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    padding: 24px;
    border: 2px solid #2e9bff;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.02);
    margin-bottom: 24px;

    @media #{$media-mobile} {
        flex-direction: column;
        justify-content: center;
        align-items: unset;
        border: 1px solid #2e9bff;
        padding: 16px 8px;
    }

    .plan-name {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #fff;
        margin-bottom: 10px;

        @media #{$media-mobile} {
            margin-bottom: 16px;
        }
    }

    .pricing {
        margin-bottom: 16px;

        .original-price {
            position: relative;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: rgba(255, 255, 255, 0.7);

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 68.225px;
                height: 2px;
                background: #c52437;
                transform: rotate(-8deg);

                @media #{$media-mobile} {
                    width: 57px;
                }
            }

            @media #{$media-mobile} {
                font-size: 16px;
            }
        }

        .discount-price {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: #fff;

            @media #{$media-mobile} {
                font-size: 18px;
            }
        }
    }

    .billing-cycle {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.72px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 16px;
    }

    .spots {
        display: flex;
        align-items: center;
        color: #fff;
        gap: 16px;

        @media #{$media-mobile} {
            flex-direction: column;
            gap: 6px;
            margin-bottom: 16px;
        }

        .user-discount {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            border-radius: 3px;
            background: #fbb35e;
            color: #000;
            padding: 6px 8px;

            @media #{$media-mobile} {
                width: 100%;
            }
        }
        .places-count {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #fbb35e;

            @media #{$media-mobile} {
                width: 100%;
                text-align: left;
            }
        }
    }

    .upgrade-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(224deg, #2e9bff -22.3%, #ff14ff 114.96%),
            #8f5dff;
        border-radius: 67px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset,
            0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset,
            0px 0px 16px 4px rgba(255, 255, 255, 0.12) inset,
            0px 0px 16px 4px rgba(143, 93, 255, 0.4), 0px 1px 2px 0px #6b26c2;

        padding: 8px 24px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #fff;

        img {
            width: 16px;
            height: 16px;
            margin-left: 8px;
        }

        @media #{$media-mobile} {
            padding: 14px 24px;
            width: 100%;
            font-size: 14px;
        }
    }
}