@import '~styles/common.scss';

.payment-plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: rgba(255, 255, 255, 0.02);

    @media #{$media-mobile} {
        flex-direction: column;
        padding: 12px 8px;
    }

    .information {
        @media #{$media-mobile} {
            display: flex;
            justify-content: space-between;
        }

        .plan-type {
            h1 {
                color: #fff;
                font-family: 'Open Sans', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 12px;
            }
        }

        .plan-tags {
            display: flex;
            gap: 12px;

            @media #{$media-mobile} {
                margin-bottom: 8px;
            }

            h1 {
                color: #fff;
                font-family: 'Open Sans', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 12px;
            }

            .plan-tag {
                height: 28px;
                padding: 4px 8px;
                border-radius: 4px;
                background: #35b5fd;
                color: #000;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

        .plan-short-description {
            color: #818898;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;

            @media #{$media-mobile} {
                margin-bottom: 20px;
            }
        }
    }

    .status-and-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;

        @media #{$media-mobile} {
            width: 100%;
        }
    }

    .status {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.08);
        background: rgba(255, 255, 255, 0.02);
        box-shadow: 0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset;

        p {
            color: #fff;
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;

            @media #{$media-mobile} {
                font-size: 12px;
            }
        }

        &.active-plan {
            border: 2px solid green;
        }

        &.inactive-plan {
            border: 2px solid darkred;
        }

        @media #{$media-mobile} {
            height: 28px;
            padding: 4px 8px;
            border-radius: 12px;
        }
    }
}
