@import '~bootstrap/scss/bootstrap';

$media-desktop: 'only screen and (max-width : 1200px)';
$media-tablet: 'only screen and (max-width : 999px)';
$media-mobile: 'only screen and (max-width : 600px)';

$color-primary: #3860fb;

body {
    background: #eff1f5;
}

* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}


//adding below css to avoide blur on toast

body.modal-open > :not(.modal){
    filter: none;
    > :not(.modal){
        filter: blur(5px);
    }
    .Toastify{
            filter: none;
        }
}