@import '~styles/common.scss';

.payment-plan {
    width: 100%;
    height: auto;
    position: relative;

    .heading {
        margin-bottom: 16px;

        h1 {
            color: #fff;
            font-family: 'Open Sans', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 8px;

            @media #{$media-mobile} {
                font-size: 18px;
                margin-bottom: 4px;
            }
        }

        p {
            color: #818898;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .card-informations {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 24px;
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.08);
        background: rgba(255, 255, 255, 0.02);

        @media #{$media-mobile} {
            padding: 12px 8px;
        }
    }

    .cancel-plan {
        cursor: pointer;
        margin-top: 12px;
        margin-bottom: 8px;
        text-decoration: underline;
        color: #7b7676;
        text-align: right;
        margin-right: 11px;
        font-size: 14px;
    }

    .cancel-cta {
        text-decoration: underline;
        color: gray;
        font-size: small;
        margin: 1em 0;
        cursor: pointer;
    }
}
