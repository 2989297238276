/* Loader Animation */

.fancy-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.fancy-loader-relative {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    margin: 2em 0;
}

.fancy-loader-inner {
    width: 100%;
    height: 100%;
    border: 10px solid #fff;
    border-radius: 50%;
    animation: rotate 5s linear infinite;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-animation: rotate 5s linear infinite;
}

@keyframes rotate {
    0% {
        transform: scale(1) rotate(360deg);
        -webkit-transform: scale(1) rotate(360deg);
        -moz-transform: scale(1) rotate(360deg);
        -ms-transform: scale(1) rotate(360deg);
        -o-transform: scale(1) rotate(360deg);
    }

    50% {
        transform: scale(0.8) rotate(-360deg);
        -webkit-transform: scale(0.8) rotate(-360deg);
        -moz-transform: scale(0.8) rotate(-360deg);
        -ms-transform: scale(0.8) rotate(-360deg);
        -o-transform: scale(0.8) rotate(-360deg);
    }

    100% {
        transform: scale(1) rotate(360deg);
        -webkit-transform: scale(1) rotate(360deg);
        -moz-transform: scale(1) rotate(360deg);
        -ms-transform: scale(1) rotate(360deg);
        -o-transform: scale(1) rotate(360deg);
    }
}

.writer-loader{
    -webkit-animation: rotate-writer 1s linear infinite;
}

@keyframes rotate-writer {
    0% {
        transform: scale(1) rotate(0deg);
        -webkit-transform: scale(1) rotate(0deg);
        -moz-transform: scale(1) rotate(0deg);
        -ms-transform: scale(1) rotate(0deg);
        -o-transform: scale(1) rotate(0deg);
    }

    100% {
        transform: scale(1) rotate(360deg);
        -webkit-transform: scale(1) rotate(360deg);
        -moz-transform: scale(1) rotate(360deg);
        -ms-transform: scale(1) rotate(360deg);
        -o-transform: scale(1) rotate(360deg);
    }
}