$media-large-desktop: 'only screen and (max-width : 1400px)';
$media-desktop: 'only screen and (max-width : 1200px)';
$media-tablet: 'only screen and (max-width : 999px)';
$media-mobile: 'only screen and (max-width : 600px)';

$color-primary: #1a76ef;
$color-black: #151515;


:root {
    --primary-blue: #3860fb;
    --navLinksGray: #858779;
    --prime-purple: #8f5dff;
    --gradient: linear-gradient(
        262deg,
        #2e9bff 0%,
        rgba(255, 20, 255, 0.7) 100%
    );
    --mobile-shadow: 0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset,
        0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset,
        0px 0px 16px 4px rgba(255, 255, 255, 0.12) inset,
        0px 0px 16px 4px rgba(143, 93, 255, 0.4), 0px 1px 2px 0px #6b26c2;
    --swiper-theme-color: var(--primary-blue);
    --swiper-pagination-bullet-size: 14px;
    --swiper-pagination-bullet-inactive-color: #313131;
    --swiper-pagination-bullet-inactive-opacity: 1;
    background-color: #eff1f5 !important;
    --main-cta-bg-color:#3d5af1;
}

.default-shiny-button {
    border-radius: 100vh;
    display: block;
    font-weight: 600;
    padding: 16px 50px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    background-color: var(--prime-purple);
    color: white;
    border: none;
    width: 100%;
    cursor: pointer;
    transition: ease all 300ms;
    text-decoration: none;
}

.default-shiny-button.glow:hover {
    box-shadow:
        0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset,
        0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset,
        0px 0px 16px 4px rgba(255, 255, 255, 0.12) inset,
        0px 0px 16px 4px rgba(143, 93, 255, 0.4),
        0px 1px 2px 0px #6b26c2;
}

.shiny-link {
    position: relative;
    overflow: hidden;
    transition: color 0.4s;
}

.shiny-link::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 300%;
    height: 100%;
    background: linear-gradient(
        45deg,
        transparent,
        rgba(255, 255, 255, 0.3),
        transparent
    );
    animation: shine 2s infinite linear;
}

@keyframes shine {
    0% {
        left: -150%;
    }

    50% {
        left: 150%;
    }

    100% {
        left: -150%;
    }
}

@mixin text-regular-small{
    font-size:16px;
    line-height:20px;
}
@mixin text-large{
    font-size:24px;
    line-height:32px;
}



// for hover state 
@property --main-cta-bg-color {
    syntax: '<color>';
    inherits: false;
    initial-value:#3d5af1;
}